import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Center } from './center.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PostingConfig } from '../vacancy/postingconfig.model';
import { BatchConfig } from '../vacancy/batchconfig.model';
import { Batch } from '../auth/_models/batch.mdel';
const helper = new JwtHelperService();


const BASE_URL = environment.BASE_URL;
const CENTER_URL =BASE_URL+'api/trainingcenter'
const REPLY_URL =BASE_URL+'api/feedbackReply'

const POSTING_CONFIG_URL =  BASE_URL+'api/postingconfig';
const API_REGISTER_URL = BASE_URL+ 'api/register';
const API_USERS_URL = BASE_URL+ 'api/user';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
const BATCH_CONFIG_URL = BASE_URL + 'api/batchconfig_2'
const BATCH_URL = BASE_URL + 'api/batch'




@Injectable({
  providedIn: 'root'
})
export class CenterService {

  constructor(private http: HttpClient) { }

  postCenter(posting: Center): Observable<any> {
    const userToken = localStorage.getItem('token');
    let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  
    posting.jwt = userToken
    
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Center>(CENTER_URL, posting,{ headers: httpHeaders })
        .pipe(res=>res
          
        );
  }

  sendReply(reply): Observable<any> {
    const userToken = localStorage.getItem('token');
    let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  
    reply.jwt = userToken
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(REPLY_URL, reply,{ headers: httpHeaders })
        .pipe(res=>res
          
        );
  }
  
  getALLCenters():Observable<Center[]> {
    const userToken = localStorage.getItem('token');
    let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  
    
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<Center[]>(CENTER_URL)
        .pipe(res=>res
          
        );
  }

  PostCenter(center: Center): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Center>(CENTER_URL, center, { headers: httpHeaders })
        .pipe(
            map((res) => {
                return res;
            }),
            catchError(err => {
                return null;
            })
        );
}



UpdateCenter(center: Center): Observable<any> {
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put<Center>(CENTER_URL, center, { headers: httpHeaders })
      .pipe(
          map((res) => {
              return res;
          }),
          catchError(err => {
              return null;
          })
      );
}

UpdateConfig(center: PostingConfig): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<Center>(POSTING_CONFIG_URL, center, { headers: httpHeaders })
        .pipe(
            map((res) => {
                return res;
            }),
            catchError(err => {
                return null;
            })
        );
  }

  UpdateBatchConfig(batch: BatchConfig): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BatchConfig>(BATCH_CONFIG_URL, batch, { headers: httpHeaders })
        .pipe(
            map((res) => {
                return res;
            }),
            catchError(err => {
                return null;
            })
        );
  }
  UpdateBatch(batch:Batch): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<Batch>(BATCH_URL, batch, { headers: httpHeaders })
        .pipe(
            map((res) => {
                return res;
            }),
            catchError(err => {
                return null;
            })
        );
  }

  CreateBatch(batch:Batch): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Batch>(BATCH_URL, batch, { headers: httpHeaders })
        .pipe(
            map((res) => {
                return res;
            }),
            catchError(err => {
                return null;
            })
        );
  }

  getCapability(can:string)
  {
   
     const userToken = localStorage.getItem('token');
     if (!userToken) {
         return of(null);
     }

     var decoded = helper.decodeToken(userToken);
      const hasPermission = decoded.data.permissions.some(x=>x.permissionDescription==can);
      return hasPermission;
    
        

  }
  
}


  



