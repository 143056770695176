import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Center } from '../../../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../../../core/center/center.service';
import { LayoutUtilsService , MessageType } from '../../../../../core/_base/crud/utils/layout-utils.service';
import { UserEditComponent } from '../../../user-management/users/user-edit/user-edit.component';
import { CenterEditComponent } from '../../../training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesService } from '../../../../../core/vacancy/vacancies.service';
import { EditPostingComponent } from '../../postings/edit-posting/edit-posting.component';
import { RepostOfficerComponent } from '../../postings/repost-officer/repost-officer.component';
// import { CenterEditComponent } from '../../../.center-edit/center-edit/center-edit.component';
import Swal from 'sweetalert2'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { Clearance } from '../../../../../core/vacancy/clearance.model';
import { SelectionModel } from '@angular/cdk/collections';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'kt-monthly-clearance',
  templateUrl: './monthly-clearance.component.html',
  styleUrls: ['./monthly-clearance.component.scss']
})
export class MonthlyClearanceComponent implements OnInit {
  user:User
  searchKey:string;
  clearanceForm:FormGroup;
  centerId:number;
  month:string;
  day:number;
  year:number;
  officer:number;
  cDetails:any;
  monthNumber:number;
  months =
  [
    {'month':'January', 'value' : 1},
    {'month':'February', 'value' : 2},
    {'month':'March', 'value'  : 3},
    {'month':'April', 'value' : 4},
    {'month':'May', 'value' : 5},
    {'month':'June', 'value' : 6},
    {'month':'July', 'value' : 7},
    {'month':'August', 'value' : 8},
    {'month':'September', 'value' : 9},
    {'month':'October', 'value' : 10},
    {'month':'November', 'value' : 11},
    {'month':'December', 'value' : 12}
  ]
   
	hasFormErrors = false;
	allcleared:any;
	stats:any;
	@BlockUI() blockUI: NgBlockUI;
	// Table fields

	listData: MatTableDataSource<Clearance>;
	selection = new SelectionModel<Clearance>(true, []);

	displayedColumns = [ 'folioNumber','firstName','lastName',  'batch', 'year','month', 'status', 'actions' ];

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	
	// Subscriptions
	private subscriptions: Subscription[] = [];

  constructor(
    private centerService: CenterService,
    private vacancyService: VacanciesService,
    private layoutUtilsService: LayoutUtilsService,
    private dialog: MatDialog,
    private clearanceFB:FormBuilder
  ) { }

  ngOnInit() {
 
		// load allusers 
    
    this.createForm();
    
    this.cDetails = this.getCenterDetails()
    if(this.cDetails){
		const date = new Date();  
const month = date.toLocaleString('default', { month: 'long' });
this.day = date.getDate();
this.month = month
this.year = date.getFullYear();
this.monthNumber = this.months.find(m=>m.month==this.month).value


      this.centerId = this.cDetails.centerId
      this.getAllCenters(this.cDetails.centerId,month,date.getFullYear())
    }

	
 
}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */
   

  createForm() {
		
	
		this.clearanceForm = this.clearanceFB.group({
	
			//centerId: [this.centerId],
			month: ['', Validators.required],
			year: ['', Validators.required]
		//	officer: [this.officer, Validators.required],
		//	jwt:[this.user.jwt],
		
			
		});
	}

	 /** Whether the number of selected elements matches the total number of rows. */
	 isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
				this.listData.data.forEach(row => this.selection.select(row));
				
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Clearance): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}
	fetchUsers() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.firstName}, ${elem.folioNumber}`,
				id: elem.id.toString(),
			
				status: elem.status
			});
		});
	
		this.layoutUtilsService.fetchElements(messages);
	}
	ViewReason(clerance) {
		const messages = [];
		
			messages.push({
				text: `${clerance.reason}`,
				id: clerance.id.toString(),
			
				status: clerance.status
			});
		
	
		this.layoutUtilsService.fetchElements(messages);
	}
	clearUsers() {
		const clerance = [];
		this.selection.selected.forEach(elem => {
			clerance.push({
				text: `${elem.folioNumber}`,
				id: elem.id.toString(),
				officer:elem.officer,
				folioNumber:elem.folioNumber,
				centerId:elem.centerId,
				
				
			});
		});
	
		//this.onBulkCleared(clerance)
	}

	getMonthValue(month)
	{
		return  this.months.find(m=>m.month==month).value
	

	}

  onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
    }
    

    const editedClerance = this.prepareClerance();
    this.getAllCenters(this.cDetails.centerId,editedClerance.month,editedClerance.year)


  }
  
  prepareClerance(): Clearance {
		const controls = this.clearanceForm.controls;
		const _clerance = new Clearance();
		_clerance.clear();
		
    _clerance.centerId =this.cDetails.centerId
    _clerance.month= controls.month.value;
    _clerance.year = controls.year.value;
    
	
		return  _clerance;
	}
	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

		//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	

	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
    
	}
	getAllCenters(centerId,month,year){
		this.blockUI.start('Fetching clearance...Please wait')
		this.vacancyService.getCleranceStatus(centerId,month,year).subscribe(
			list=>{
				let array = list.data.map(item =>{
					return item
				})

				this.allcleared = list.data;
				this.stats = list.stat;
        
       this.blockUI.stop();
        this.listData = new MatTableDataSource(array)
       
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
			},
			error=>{

			}
			)

	}

	getAllClearances(centerId,month,year){
		
		this.vacancyService.getCleranceStatus(centerId,month,year).subscribe(
			list=>{
				let array = list.data.map(item =>{
					return item
				})
				this.allcleared = list.data;
				this.stats = list.stat;
       this.blockUI.stop();
        this.listData = new MatTableDataSource(array)
       
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
			},
			error=>{

			}
			)

  }
  getCenterDetails(){
return this.vacancyService.getCenterDetails();
  }

	applyFilter(){
		
		this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  
  onCreate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(EditPostingComponent,dialogConfig )
  }
  onRepost(){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(RepostOfficerComponent,dialogConfig )
  }
 onEdit(center,id) {
	
	const dialogConfig = new MatDialogConfig();
	dialogConfig.disableClose = true;
	dialogConfig.autoFocus = true;
	dialogConfig.width = "60%";
	dialogConfig.data= center;
	this.dialog.open(EditPostingComponent,dialogConfig)
	//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
}
onMonthlyCleared(center){
	

  this.hasFormErrors = false;
  const controls = this.clearanceForm.controls;
  /** check form */
  if (this.clearanceForm.invalid) {
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );

    this.hasFormErrors = true;
    //this.selectedTab = 0;
    return;
  }
  

  const editedClerance = this.prepareClerance();
 

  Swal.fire({
    title: 'Are you sure you want to clear '+center.firstName+ ' for '+editedClerance.month,
    text: 'Once cleared, the process is irreversible',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, I want to clear the Officer',
    cancelButtonText: 'Cancel'
    }).then((result) => {
    if (result.value) {
			editedClerance.officer = center.officer
			editedClerance.isCleared = true;
     // alert(JSON.stringify(editedClerance))
      this.clearOfficer(editedClerance)
     // this.postOfficer(controls.HouseManshipCenters.value)
    
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
      'Cancelled',
      'Clearance cancelled',
      'error'
      )
    }
    })
}

onMonthlyUnCleared(center){

  this.hasFormErrors = false;
  const controls = this.clearanceForm.controls;
  /** check form */
  if (this.clearanceForm.invalid) {
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );

    this.hasFormErrors = true;
    //this.selectedTab = 0;
    return;
  }
  

  const editedClerance = this.prepareClerance();
 

  Swal.fire({
    title: 'Are you sure you do not want to clear '+center.firstName+ ' for '+editedClerance.month,
    text: 'Once cleared, the process is irreversible',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, proceed to unclear',
    cancelButtonText: 'Cancel'
    }).then((result) => {
    if (result.value) {

			Swal.fire({
				title: 'Kindly state the reason for not clearing this officer',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Continue',
				showLoaderOnConfirm: true,
				preConfirm: (reason) => {

					editedClerance.reason = reason;
					
					editedClerance.officer = center.officer
					// alert(JSON.stringify(editedClerance))
					
					// this.postOfficer(controls.HouseManshipCenters.value)
			
				
					this.clearOfficer(editedClerance)

				},

			})
     
    
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
      'Cancelled',
      'Clearance cancelled',
      'error'
      )
    }
    })
}
clearOfficer(clearance:Clearance){
	this.blockUI.start('Processing clearance...Please wait')
  this.vacancyService.clearOfficer(clearance).subscribe(data=>{
		this.blockUI.stop()
    Swal.fire(
      'Processed',
      'Clearance Processed',
      'success'
			)
			
    this.getAllClearances(this.cDetails.centerId,clearance.month,clearance.year)
  },error=>{
		Swal.fire(
      error.error.message,
      'Clearance was not processed',
      'error'
      )

	})
}
 
}
